import './scss/style.scss'
import './i18n'

import { PermifyProvider } from '@permify/react-role'
import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Login = React.lazy(() => import('./views/login/Login'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

class App extends Component {
  render() {
    return (
      <PermifyProvider>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/" name="Login Page" element={<Login />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </PermifyProvider>
    )
  }
}

export default App

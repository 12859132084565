import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostWithTokenRequest } from 'src/stores/services/api'

const initialState = {
    logout: [],
    loading: '',
}

export const logOut = createAsyncThunk('logOutSlice/logOut', async (payload, thunkAPI) => {
    try {
        const finalPayload = {
            email: payload.email,
            tenant: payload.tenant,
        }

        const token = payload.token

        const response = await PostWithTokenRequest(process.env.REACT_APP_API_END_POINT, 'auth/logout', token, finalPayload)

        return response
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response)
    }
})

export const logOutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(logOut.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(logOut.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.logout.push(action.payload)
            })
            .addCase(logOut.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default logOutSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostRequest } from 'src/stores/services/api'

const initialState = {
    forgotPassword: [],
    loading: '',
}

export const forgotPassword = createAsyncThunk('forgotPasswordSlice/forgotPassword', async (payload, thunkAPI) => {
    try {
        const finalPayload = {
            email: payload.email,
            tenant: payload.tenant,
            password: payload.password,
            confirmpassword: payload.confirmpassword,
        }

        const response = await PostRequest(process.env.REACT_APP_API_END_POINT, 'auth/forgot-password', finalPayload)

        return response
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response)
    }
})

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(forgotPassword.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.forgotPassword.push(action.payload)
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default forgotPasswordSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    auth: [],
    loading: '',
}

// A function that accepts a Redux action type string and a callback function that should return a promise
export const authUser = createAsyncThunk('authSlice/authUser', async (payload, thunkAPI) => {
    try {
        const response = await PostRequest(process.env.REACT_APP_API_END_POINT, 'auth/login', payload)
        //  const state = thunkAPI.getState();
        // thunkAPI.dispatch(onTokenChange(response.headers.Authorization));

        return response // will dispatch `fulfilled` action)
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return thunkAPI.rejectWithValue(err.response)
    }
})

export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(authUser.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(authUser.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.auth.push(action.payload)
            })
            // rome-ignore lint: redux syntax
            .addCase(authUser.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default authSlice.reducer

import axios from 'axios'
import PropTypes from 'prop-types'

const Headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Content-Type': '*',
    'Access-Control-Allow-Methods': 'POST',
}

export async function GetRequest(baseURL, url, token, params) {
    const Headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, OPTIONS',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + token,
    }

    const instance = axios.create({
        method: 'GET',
        baseURL: baseURL,
        headers: Headers,
        withCredentials: false,
    })
    const newParams = new URLSearchParams(params)
    return await instance.get(url + newParams)
}

export const PostRequest = (baseURL, url, payload) => {
    const Headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'POST',
    }

    const instance = axios.create({
        baseURL: baseURL,
        headers: Headers,
    })

    return instance.post(url, payload)
}

export async function PostWithTokenRequest(baseURL, url, token, payload) {
    const Headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'POST',
        Authorization: 'Bearer ' + token,
    }

    const instance = axios.create({
        baseURL: baseURL,
        headers: Headers,
    })

    return await instance.post(url, payload)
}

export async function PatchWithTokenRequest(baseURL, url, token, payload) {
    const Headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'PATCH',
        Authorization: 'Bearer ' + token,
    }

    const instance = axios.create({
        baseURL: baseURL,
        headers: Headers,
    })

    return await instance.patch(url, payload)
}

export async function DownloadRequest(baseURL, url, params) {
    const instance = axios.create({
        baseURL: baseURL,
        headers: Headers,
        responseType: 'blob',
    })

    const newParams = new URLSearchParams(params)
    return await instance.post(url + newParams)
}

PostRequest.propTypes = {
    baseURL: PropTypes.any,
    url: PropTypes.string.isRequired,
    data: PropTypes.any,
}

PostWithTokenRequest.propTypes = {
    baseURL: PropTypes.any,
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    payload: PropTypes.any,
}

PatchWithTokenRequest.propTypes = {
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    payload: PropTypes.any,
}

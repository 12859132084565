import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    health: [],
    loading: "",
};

// A function that accepts a Redux action type string and a callback function that should return a promise
export const getHealth = createAsyncThunk("healthSlice/getHealth", async (payload, thunkAPI) => {
    try {
        // const state = thunkAPI.getState();
        const token = payload.token
        const response = await GetRequest(process.env.REACT_APP_EXTAPI_END_POINT, "sms/health", token);
        return response.data;
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return thunkAPI.rejectWithValue(await err.response.data);
    }
});

export const healthSlice = createSlice({
    name: "health",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(getHealth.pending, (state, action) => {
                state.loading = "pending";
            })
            .addCase(getHealth.fulfilled, (state, action) => {
                state.loading = "fulfilled";
                state.health.push(action.payload);
            })
            // rome-ignore lint: redux syntax
            .addCase(getHealth.rejected, (state, action) => {
                state.loading = "rejected";
            });
    },
});

export default healthSlice.reducer;

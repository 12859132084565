import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostWithTokenRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    data: [],
    loading: '',
}

// A function that accepts a Redux action type string and a callback function that should return a promise
export const updateLoanPayment = createAsyncThunk(
    'updateLoanPaymentSlice/updateLoanPayment',
    async (payload, thunkAPI) => {
        try {
            // const state = thunkAPI.getState();
            // const credentials = state.cred;
            const finalPayload = {
                id: payload.id,
                //transactionId: payload.transactionId,
                //loanApplicationId: payload.loanApplicationId,
                storeNo: payload.storeNo,
                accountReference: payload.accountReference,
                //msisdn: payload.msisdn,                
                comment: payload.comment,
                status: payload.status,
                enabled: payload.enabled
            }

            const token = payload.token

            const response = await PostWithTokenRequest(process.env.REACT_APP_API_END_POINT, 'collections', token, finalPayload)
            return response.data //return data only
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return thunkAPI.rejectWithValue(await err.response.data)
        }
    },
)

export const updateLoanPaymentSlice = createSlice({
    name: 'updateLoan',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(updateLoanPayment.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(updateLoanPayment.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.data.push(action.payload)
            })
            // rome-ignore lint: redux syntax
            .addCase(updateLoanPayment.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default updateLoanPaymentSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    payments: [],
    loading: '',
}

// A function that accepts a Redux action type string and a callback function that should return a promise
export const getLoanPayment = createAsyncThunk(
    'loanPaymentSlice/getLoanPayment',
    async (payload, thunkAPI) => {
        try {
            // const state = thunkAPI.getState();
            // const credentials = state.cred;
            const finalPayload = {
                includes: payload.includes,
                sort: payload.sort,
                order: payload.order,
                filters: payload.filters,
                fields: payload.fields,
            }

            const token = payload.token

            const response = await GetRequest(process.env.REACT_APP_API_END_POINT, 'collections?', token, finalPayload)
            return response.data //return data only
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return thunkAPI.rejectWithValue(await err.response.data)
        }
    },
)

export const loanPaymentSlice = createSlice({
    name: 'payments',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(getLoanPayment.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getLoanPayment.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.payments.push(action.payload)
            })
            // rome-ignore lint: redux syntax
            .addCase(getLoanPayment.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default loanPaymentSlice.reducer

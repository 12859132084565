import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostWithTokenRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    accountstatus: [],
    loading: "",
};

// A function that accepts a Redux action type string and a callback function that should return a promise
export const getAccountstatus = createAsyncThunk(
    "accountStatuSlice/getAccountstatus",
    async (payload, thunkAPI) => {
        try {
            // const state = thunkAPI.getState();
            // const credentials = state.cred;
            const finalPayload = {
                Username: payload.sender_username,
                Password: payload.sender_password,
                Response: payload.response,
            };

            const token = payload.token

            const response = await PostWithTokenRequest(process.env.REACT_APP_EXTAPI_END_POINT, "sms/account/status", token, finalPayload);
            return response.data;
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return thunkAPI.rejectWithValue(await err.response.data);
        }
    }
);

export const accountStatuSlice = createSlice({
    name: "accountstatus",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(getAccountstatus.pending, (state, action) => {
                state.loading = "pending";
            })
            .addCase(getAccountstatus.fulfilled, (state, action) => {
                state.loading = "fulfilled";
                state.accountstatus.push(action.payload);
            })
            // rome-ignore lint: redux syntax
            .addCase(getAccountstatus.rejected, (state, action) => {
                state.loading = "rejected";
            });
    },
});

export default accountStatuSlice.reducer;

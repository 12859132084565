import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetRequest } from 'src/stores/services/api'

// Define the initial state using that type
const initialState = {
    dashboard: [],
    loading: '',
}

// A function that accepts a Redux action type string and a callback function that should return a promise
export const getDashboard = createAsyncThunk(
    'dashboardSlice/getDashboard',
    async (payload, thunkAPI) => {
        try {
            // const state = thunkAPI.getState();
            // const credentials = state.cred;

            const token = payload.token

            const response = await GetRequest(process.env.REACT_APP_API_END_POINT, 'dashboard', token)
            return response
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return thunkAPI.rejectWithValue(await err.response.data)
        }
    },
)

export const dashboardSlice = createSlice({
    name: 'dashboard',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // A builder provides addCase, addMatcher and addDefaultCase functions that may be called to define what actions this reducer will handle
        builder
            // Add a case reducer to handle a single exact action type
            // rome-ignore lint: redux syntax
            .addCase(getDashboard.pending, (state, action) => {
                state.loading = 'pending'
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.loading = 'fulfilled'
                state.dashboard.push(action.payload)
            })
            // rome-ignore lint: redux syntax
            .addCase(getDashboard.rejected, (state, action) => {
                state.loading = 'rejected'
            })
    },
})

export default dashboardSlice.reducer

/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
const initialState = {
	sidebarShow: true,
};

export const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case "set":
			return { ...state, ...rest };
		default:
			return state;
	}
};

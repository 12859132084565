import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import { DESTROY_SESSION } from './actionCreators'
import { authSlice } from './features/auth/authSlice'
import { forgotPasswordSlice } from './features/auth/forgotPasswordSlice'
import { logOutSlice } from './features/auth/logOutSlice'
import { accountProfileSlice } from "./features/bulksms/accountProfileSlice";
import { accountStatuSlice } from "./features/bulksms/accountStatuSlice";
import { contactSlice } from "./features/bulksms/contactSlice";
import { healthSlice } from "./features/bulksms/healthSlice";
import { quickGroupSmSlice } from './features/bulksms/quickGroupSmSlice'
import { quickSmSlice } from './features/bulksms/quickSmSlice'
import { smsgroupSlice } from "./features/bulksms/smsgroupSlice";
import { smstemplateSlice } from "./features/bulksms/smstemplateSlice";
import { updateContactSlice } from './features/bulksms/updateContactSlice'
import { clientSlice } from './features/clients/clientSlice'
import { updateClientSlice } from './features/clients/updateClientSlice'
import { dashboardSlice } from './features/dashboard/dashboardSlice'
import { floatTransactionSlice } from './features/loans/floatTransactionSlice'
import { loanApplicationSlice } from './features/loans/loanApplicationSlice'
import { loanPaymentSlice } from './features/loans/loanPaymentSlice'
import { updateLoanApplicationSlice } from './features/loans/updateLoanApplicationSlice'
import { updateLoanPaymentSlice } from './features/loans/updateLoanPaymentSlice'
import { logSlice } from './features/logs/logSlice'
import { settingSlice } from './features/settings/settingSlice'
import { changeState } from './features/sidebar/sidebarSlice'

const sliceReducers = combineReducers({
    auth: authSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    settings: settingSlice.reducer,
    dashboard: dashboardSlice.reducer,
    floats: floatTransactionSlice.reducer,
    clients: clientSlice.reducer,
    updateClient: updateClientSlice.reducer,
    loans: loanApplicationSlice.reducer,
    updateLoan: updateLoanApplicationSlice.reducer,
    payments: loanPaymentSlice.reducer,
    updateLoanPayment: updateLoanPaymentSlice.reducer,
    health: healthSlice.reducer,
    contacts: contactSlice.reducer,
    updateContact: updateContactSlice.reducer,
    accountstatus: accountStatuSlice.reducer,
    accountprofile: accountProfileSlice.reducer,
    smsgroups: smsgroupSlice.reducer,
    smstemplates: smstemplateSlice.reducer,
    quicksms: quickSmSlice.reducer,
    quickgroupsms: quickGroupSmSlice.reducer,
    logs: logSlice.reducer,
    logout: logOutSlice.reducer,
})

const rootReducer = (state, action) => {
    if (action.type === DESTROY_SESSION) {
        state = undefined
    }

    return sliceReducers(state, action)
}

const middlewares = [save({ ignoreStates: ['changeState'] })]

export const store = configureStore({
    reducer: combineReducers({ rootReducer, changeState }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: load({ ignoreStates: ['changeState', 'logs'] }),
})

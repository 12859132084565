/* eslint no-dupe-keys: 0 */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    chooseLanguage: 'Choose Language',
                    loginPageHeader: 'Login',
                    logout: 'Log Out',
                    loginFormHeader: 'Sign In to your account',
                    loginForgotPassword: 'Forgot password ?',
                    confirmPassword: 'Confirm Password',
                    tenant: 'Tenant',
                    username: 'Username',
                    email: 'Email Address',
                    loginSignup: 'Sign up',
                    loginRegisterNow: 'Register Now!',
                    intro:
                        'An end-to-end, next generation banking solution that enables Credit Unions, SACCOs and Neo-Banks to build great banking experiences that their customers will love; in a faster and more flexible way than ever before.',
                    profileOffice: 'Office',
                    profileRoles: 'Roles',
                    profileCapabilities: 'Capabilities',
                    profileChangePassword: 'Change Password',
                    password: 'Password',
                    repeatPassword: 'Confirm Password',
                    Close: 'Close',
                    SaveChanges: 'Save Changes',
                    Search: 'Search',
                    rolesAddNew: 'Add New Role',
                    rolesName: 'Role Name',
                    rolesAssigned: 'Assign Role',
                    rolesDescription: 'Role Description',
                    rolesisDisabled: 'Is Disabled ?',
                    rolesEnableDisable: 'Enable /Disable Role',
                    permissions: 'Permissions',
                    addNewOffice: 'Add New Office',
                    officeName: 'Office Name',
                    openingDate: 'Opening Date',
                    externalID: 'External ID',
                    hierachy: 'Hierachy',
                    createOffice: 'Create an Office',
                    spacesAllowed: 'Spaces allowed',
                    parentOffice: 'Parent Office',
                    spacesNotAllowed: 'Spaces NOT allowed',
                    details: 'Details',
                    fillinAllDetails: 'Fill in all details',
                    addNewStaff: 'Add New Staff',
                    staffName: 'Staff Name',
                    staffAssigned: 'Assign Staff',
                    isLoanOfficer: 'Is Loan officer ?',
                    isActive: 'Is Active ?',
                    NotActive: 'Not Active',
                    selfServiceAccount: 'Self Service Account',
                    createStaff: 'Add New Staff',
                    fullName: 'Full Name',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    joiningDate: 'Joining Date',
                    mobileNumber: 'Mobile Number',
                    groupsName: 'Group Name',
                    groupAccountName: 'Group Account No.',
                    groupLoanOfficer: 'Group Loan Officer',
                    groupSubmittedDate: 'Group Creation Date',
                    groupsAddNew: 'Add New Group',
                    client: 'Client',
                    clients: 'Clients',
                    activationDate: 'Member Activation Date',
                    officeJoiningDate: 'Office Joining Date',
                    isStaff: 'Is Staff ?',
                    groupClientName: 'Group Client Name',
                    groupClientAccountName: 'Group Client A/c No.',
                    createUser: 'Create New User',
                    addNewUser: 'Add New User',
                    update: 'Update',
                    profileChangePasswordfor: 'Change Password for ',
                    addClient2Group: 'Add Client to Group',
                    addNewClient: 'Add New Client',
                    clientName: 'Client Name',
                    clientAccountName: 'Client A/c No.',
                    createClient: 'Create New Client',
                    assignStaff: 'Assign Staff',
                    assign: 'Assign',
                    updateCurrency: 'Update Allowed Currency',
                    currencyName: 'Currency Name',
                    currencyDisplayName: 'Currency Display Name',
                    currencyNameCode: 'Currency Name Code',
                    currencyDecimalPlaces: 'Currency Decimal Places',
                    paymentTypeName: 'Payment Type Name',
                    isCashPayment: 'is Cash Payment ?',
                    position: 'Position',
                    delete: 'Delete',
                    addNewPaymentType: 'Add New Payment Type',
                    updatePaymentType: 'Update Payment Type:',
                    addNewGL: 'Add New General Ledger Account',
                    glAccountName: 'General Ledger Account Name',
                    glCode: 'General Ledger Code',
                    manualEntriesAllowed: 'Manual Entries Allowed',
                    accountUsage: 'Account Usage',
                    isglActive: 'is GL Active ?',
                    createGL: 'Create a General Ledger',
                    glName: 'GL Account Name',
                    glDescription: 'GL Description',
                    gledgerType: 'GL Type',
                    glUsage: 'GL Account Usage',
                    isManualEntryAllowed: 'is Manual Entry Allowed ?',
                    Asset: 'Asset',
                    Liability: 'Liability',
                    Equity: 'Equity',
                    Income: 'Income',
                    Expense: 'Expense',
                    Detail: 'Detail',
                    Header: 'Header',
                    accountingRuleName: 'Accounting Rule Name',
                    debitTags: 'Debit Tags',
                    creditTags: 'Credit Tags',
                    addNewAccountingRule: 'Add New Accounting Rule',
                    accountingRule: 'Accounting Rule',
                    updateAccountingRule: 'Update Accounting Rule',
                    Description: 'Description',
                    accountToDedit: 'Account to Debit',
                    accountToCredit: 'Account to Credit',
                    accountNoFormat: 'Account Number Preferences',
                    prefixType: 'Account Number Prefix',
                    addNewAccountNumberFormat: 'Create Preference',
                    updateAccountNoFormat: 'Update Account Number Preference',
                    accountType: 'Account Type',
                    addFund: 'Add Fund',
                    fundName: 'Fund Name',
                    updateFund: 'Update Fund',
                    addFloatingRate: 'Add Floating Rate',
                    floatingRateName: 'Floating Rate Name',
                    isBaseLendingRate: 'is Base Lending Rate ?',
                    createdBy: 'Created by',
                    createdOn: 'Created Date',
                    modifiedBy: 'Modified by',
                    modifiedOn: 'Modified Date',
                    adjust: 'Adjust',
                    updateFR: 'Update Floating Rate',
                    ratePeriod: 'Rate Period',
                    updateFRPeriod: 'Update Floating Rate',
                    interestRate: 'Interest Rate (%)',
                    interestMethod: 'Interest Method',
                    transactionID: 'Transaction ID',
                    accountName: 'Account Name',
                    transactionDate: 'Transaction Date',
                    entityType: 'Entity Type',
                    isreversed: 'is Reversed ?',
                    action: 'Action',
                    remove: 'Remove',
                    addNewJE: 'Add New Journal Entry',
                    reverse: 'Reverse',
                    ismanualEntry: 'is Manual Entry ?',
                    addNewJournalEntry: 'Add New Balanced Journal Entry',
                    creditAmount: 'Credit Amount',
                    debitAmount: 'Debit Amount',
                    accountNo: 'Account No.',
                    chequeNo: 'Cheque No.',
                    receiptNo: 'Receipt No.',
                    bankNo: 'Bank No.',
                    routingCode: 'Routing Code',
                    description: 'Description',
                    paymentType: 'Payment Type',
                    currency: 'Currency',
                    taxComponent: 'Tax Component',
                    taxComponent1: 'Tax Component 2',
                    addTaxComponent: 'Add Tax Component',
                    taxcomponentName: 'Tax Component Name',
                    updateTaxComponent: 'Update Tax Component',
                    percentage: 'Percentage (%)',
                    creditAccountType: 'Credit Account Type',
                    creditAccountId: 'Credit Account Id',
                    startDate: 'Start Date',
                    endDate: 'End Date',
                    addNewTaxComponent: 'Add New Tax Component',
                    creditAccount: 'Credit Account',
                    updateTaxGroup: 'Update Tax Group',
                    taxgroupName: 'Tax Group Name',
                    taxGroup: 'Tax Group',
                    addNewTaxGroup: 'Add New Tax Group',
                    view: 'View',
                    createCharge: 'Create Charge',
                    addNewCharge: 'Add New Charge',
                    charges: 'Charges',
                    chargeAppliesTo: 'Charge Applies To',
                    chargePaymentMode: 'Charge Payment Mode',
                    chargeName: 'Charge Name',
                    chargeTimeType: 'Charge Time Type',
                    chargeCalculationType: 'Charge Calculation Type',
                    minCap: 'Minimum Cap',
                    maxCap: 'Maximum Cap',
                    feeFrequency: 'Fee Frequency',
                    feeOnMonthDay: 'Fee on Month Day',
                    feeInterval: 'Fee Interval',
                    amount: 'Amount',
                    TaxGroup: 'Tax Group',
                    isPenalty: 'is Penalty',
                    createLoanProduct: 'Create Loan Product',
                    updateLoanProduct: 'Update Loan Product',
                    mandatoryDetails: 'Mandatory Details',
                    productTerms: 'Product Terms',
                    productSettings: 'Product Settings',
                    accounting: 'Accounting',
                    productName: 'Product Name',
                    productShortName: 'Product Short Name',
                    fund: 'Fund',
                    includeinCustomerCounter: 'Include in Customer Counter',
                    closeDate: 'Close Date',
                    digitsAfterDecimal: 'Digits After Decimal',
                    inMultiplesOf: 'Currency In Multiples of',
                    installmentsinMultipleOf: 'Installments in Multiples of',
                    Principal: 'Principal',
                    defaultPrincipal: 'Default Principal',
                    minPrincipal: 'Minimum Principal',
                    maxPrincipal: 'Maximum Principal',
                    defaultNoOfRepayments: 'Default No. of Loan Repayments',
                    noOfRepayments: 'No. of Repayments',
                    repaymentEvery: 'Repay Loan Every',
                    repaymentFrequencyType: 'Repayment Frequency Type',
                    interestRates: 'Interest Rates',
                    isLinkedtoFLoatingInterestRates: 'is Linked to Floating Interest Rates ?',
                    interestRatePerPeriod: 'Interest Rate Per Period',
                    interestRateFrequencyType: 'Interest Rate Frequency Type',
                    interestType: 'Interest Type',
                    interestCalculationPeriodType: 'Interest Calculation Period Type',
                    interestCalculationPeriod: 'Interest Calculation Period',
                    amortizationType: 'Amortization Type',
                    repaymentStrategy: 'Transaction Processing Strategy',
                    inArrearsTolerance: 'in Arrears Tolerance',
                    minimumDaysBetweenDisbursalAndFirstRepayment:
                        'Minimum Days Between Disbursal And First Repayment',
                    principalThresholdForLastInstalment: 'Principal Threshold ForLast Instalment',
                    graceOnPrincipalPayment: 'Grace on Principal Payment',
                    graceOnInterestPayment: 'Grace on Interest Payment',
                    graceOnInterestCharged: 'Grace on Interest Charged',
                    graceOnArrearsAgeing: 'Grace on Arrears Ageing',
                    overdueDaysForNPA: 'Overdue Days for NPA',
                    daysInYearType: 'Days in Year Type',
                    daysInMonthType: 'Days in Month Type',
                    InterestRecalculation: 'Interest Recalculation',
                    interestRecalculationCompoundingMethod: 'Interest Recalculation Compounding Method',
                    rescheduleStrategyMethod: 'Reschedule Strategy Method',
                    recalculationCompoundingFrequencyType: 'Recalculation Compounding Frequency Type',
                    recalculationCompoundingFrequencyInterval: 'Recalculation Compounding Frequency Interval',
                    recalculationCompoundingFrequencyDate: 'Recalculation Compounding Frequency Date',
                    recalculationRestFrequencyType: 'Recalculation Rest Frequency Type',
                    recalculationRestFrequencyInterval: 'Recalculation Rest Frequency Interval',
                    recalculationRestFrequencyDate: 'Recalculation Rest Frequency Date',
                    preClosureInterestCalculationStrategy: 'Pre-Closure Interest Calculation Strategy',
                    isArrearsBasedOnOriginalSchedule: 'is Arrears Based on Original Schedule',
                    guaranteeRequirements: 'Guarantee Requirements',
                    placeGuaranteeFundsonHold: 'Place Guarantee Funds on Hold',
                    mandatoryGuarantee: 'Mandatory Guarantee',
                    minimumGuaranteeFromOwnFunds: 'Minimum Guarantee From Own Funds',
                    minimumGuaranteeFromGuarantor: 'Minimum Guarantee From Guarantor',
                    allowAttributeOverrides: 'Allow Attribute Overrides',
                    isInterestRecalculationEnabled: 'is Interest Recalculation Enabled ?',
                    charge: 'Charge',
                    accountingRules: 'Accounting Rules',
                    fundSource: 'Fund Source',
                    loanPortfolio: 'Loan Portfolio',
                    transfersInSuspenseAccount: 'Transfers in Suspense Account',
                    receivableInterestAccount: 'Receivable Interest Account',
                    receivableFeeAccount: 'Receivable Fee Account',
                    incomeFromFeeAccount: 'Income from Fee Account',
                    incomeFromPenaltyAccount: 'Income from Penalty Account',
                    incomeFromRecoveryAccount: 'Income from Recovery Account',
                    interestOnLoanAccount: 'Interest on Loan Account',
                    writeOffAccount: 'Write Off Account',
                    overpaymentLiabilityAccount: 'Overpayment Liability Account',
                    receivablePenaltyAccount: 'Receivable Penalty Account',
                    chargeChoices:
                        'Enumeration for indicating whether charge is to be applicable for loans or clients',
                    chargeCalculationChoices:
                        'Enumeration for indicating whether charge amount is flat or percentage where "% of Amount" for Loans refers to the loan principal disbursed',
                    chargeTimeTypeChoices:
                        'For setting recurring charge due date "Fee on Month Day" set recurring charge type to Monthly or Annual',
                    chargeMinCapChoices:
                        'Used to enforce a minimum charge amount in cases where the calculated amount is less than the minimum amount provided',
                    chargeMaxCapChoices:
                        'Used to enforce a maximum charge amount in cases where the calculated amount is greater than the maximum amount provided',
                    updateCharge: 'Update Charge',
                    inMultiplesOfHelp:
                        'Override the default value for rounding currency to multiples of value provided. For example, if you put "multiples of 100", the currency value will be rounded off to 200, 300, 400, etc.',
                    installmentsinMultipleOfHelp:
                        'Override the default value for rounding instalment amount to multiples of value provided. For example: 10, so Installment amount gets rounded off to 10s. ',
                    amortizationTypeHelp:
                        'Depending upon the type of amortization you practice, select one.  Equal Installments - All repayment amounts will be equal but the principal and interest amounts will vary with each repayment.            Or Equal Principal Payments - All principal amounts will be equal but the repayment and interest amounts will vary with each repayment.',
                    transactionProcessingStrategyHelp:
                        'The repayment strategy determines the sequence in which each of the components is paid. The following strategies are supported: ',
                    MifostransactionProcessingStrategyHelp:
                        'Mifos Strategy =>  Overdue and due penalties, Overdue and due fees, Overdue and due interest, Overdue and due principal ',
                    PIPFOtransactionProcessingStrategyHelp:
                        'Principal Interest Penalties Fees Order => Overdue and due principal, Overdue and due interest, Overdue and due penalties, Overdue and due fees ',
                    IPPFOtransactionProcessingStrategyHelp:
                        'Interest Principal Penalties Fees Order =>  Overdue and due interest, Overdue and due principal, Overdue and due penalties, Overdue and due fees',
                    RBItransactionProcessingStrategyHelp:
                        'RBI (India) =>  Per RBI regulations, all overdue should be paid, only then can the due amount will be collected. When the overdue amount is paid, the payment order will be overdue penalties, overdue fees, overdue principal. Only after the payment of overdue, the due is collected and the payment order will be due fees, due interest and due principal.',
                    inArrearsToleranceHelp:
                        'The amount that can be "waived" at end of all loan payments because it is too small to worry about. This is also the tolerance amount assessed when determining if a loan is in arrears.',
                    principalThresholdForLastInstalmentHelp:
                        'Field represents percentage of current instalment principal amount for comparing against principal outstanding to add another repayment instalment. If the outstanding principal amount is less then calculated amount, remaining outstanding amount will be added to current instalment. Default value for multi disburse loan is 50% and non-multi disburse loan is 0% ',
                    graceOnPrincipalPaymentHelp:
                        'Represents the number of repayment periods that grace should apply to the principal component of a repayment period. ',
                    graceOnInterestPaymentHelp:
                        'Represents the number of repayment periods that grace should apply to the interest component of a repayment period. Interest is still calculated but offset to later repayment periods. ',
                    graceOnInterestChargedHelp:
                        'Represents the number of repayment periods that should be interest-free. ',
                    graceOnArrearsAgeingHelp:
                        'Used in Arrears calculation to only take into account loans that are more than graceOnArrearsAgeing days overdue.',
                    overdueDaysForNPAHelp:
                        'Represents the maximum number of days a Loan may be overdue before being classified as a NPA (non performing asset)',
                    loanproductAddNew: 'Add New Loan Product',
                    loanName: 'Loan Name',
                    loanProduct: 'Loan Product',
                    applyLoan: 'Apply for New Loan',
                    principal: 'Principal',
                    addNewLoan: 'Add New Loan',
                    submittedOnDate: 'Submitted on Date',
                    submittedByUsername: 'Submitted by Username',
                    expectedMaturityDate: 'Expected Maturity Date',
                    approval: 'Aprroval',
                    summary: 'Summary',
                    terms: 'Terms',
                    transactionProcessingStrategy: 'Transaction Processing Strategy',
                    loanType: 'Loan Type',
                    expectedDisbursementDate: 'Expected Disbursement Date',
                    loanTermFrequency: 'Loan Term Frequency',
                    loanTermFrequencyType: 'Loan Term Frequency Type',
                    loanApplication: 'Loan Application',
                    loanApplicationHelp:
                        'Start loan application by first choosing `Loan Type`, `Loan Product`, `Group` OR`Client`. Then click on `summary` to confirm the details of your application before submission. ',
                    lengthofLoanTerm: 'Length of Loan Term',
                    disburseLoan: 'Disburse Loan',
                    transactionAmount: 'Transaction Amount',
                    actualDisbursementDate: 'Actual Disbursement Date',
                    accountNumber: 'Account Number',
                    checkNumber: 'Cheque Number',
                    receiptNumber: 'Receipt Number',
                    bankNumber: 'Bank Number',
                    pendingApproval: 'Pending Approval',
                    approved: 'Approved',
                    approve: 'Approve',
                    disburse: 'Disburse',
                    approveLoan: 'Approve Loan',
                    approvedAmount: 'Approved Amount',
                    approvedRejectedDate: 'Approved / Rejected Date',
                    approvedRejected: 'Approved / Rejected',
                    Reject: 'Reject',
                    Approve: 'Approve',
                    reasonRejection: 'Reason for Rejection',
                    nominalAnnualInterestRate: 'Nominal Annual Interest Rate',
                    savingproductAddNew: 'Add New Savings Product',
                    savingName: 'Name of Savings',
                    savingsInterestRateHelp: 'The default interest rate set when creating savings accounts',
                    savingsInterestCompoundingPeriodTypeHelp:
                        'The period at which interest rate is compounded',
                    savingsinterestPostingPeriodTypeHelp:
                        'The period at which interest rate is posted or credited to a saving account',
                    savingsinterestCalculationTypeHelp: 'The method used to calculate interest',
                    savingsinterestCalculationDaysInYearTypeHelp:
                        'The setting for number of days in year to use to calculate interest',
                    savingsminRequiredOpeningBalanceHelp:
                        'Sets the minimum deposit amount required to open a saving account of this saving product type',
                    savingsminBalanceForInterestCalculationHelp:
                        'Sets the balance required for interest calculation',
                    savingsminRequiredBalanceHelp: 'Sets the minimum balance allowed for a saving account',
                    savingslockinPeriodFrequencyHelp:
                        'Used to indicate the length of time that a savings account of this saving product type is locked-in and withdrawals are not allowed',
                    savingsEnforceMinRequiredBalanceHelp:
                        'Enforce Minimum Required Balance: Indicates whether to enforce a minimum balance',
                    savingsOverdraftAllowedHelp:
                        'Overfraft Allowed: Indicates whether saving accounts based on this saving product may have an overdraft',
                    savingsDormancyTrackingHelp:
                        'Dormancy Tracking: Enables definition and tracking inactive Savings Accounts',
                    savingsReferenceAccountHelp:
                        'An Asset account that is used a suspense account for tracking Portfolio of Loans under transfer',
                    savingsinterestOnSavingsAccountHelp:
                        'An Expense account, which is debited when interest is due to be paid to the customer',
                    savingsControlAccountHelp:
                        'A Liability account which denotes the Savings deposit accounts portfolio and is credited when a deposit is made and debited when a withdrawal is done',
                    savingsincomeFromFeeAccountHelp:
                        'An Income account which is credited when a fee is paid by account holder on this account',
                    savingsincomeFromPenaltyAccountHelp:
                        'An Income account, which is credited when a penalty is paid by account holder on this account',
                    savingWithHoldTaxHelp:
                        'WithHold Tax: An boolean flag to attach  taxes to interest posting',
                    interestCompoundingPeriodType: 'Interest Compounding Period',
                    interestPostingPeriodType: 'Interest Posting Period',
                    interestCalculationType: 'Interest Calculated Using ?',
                    interestCalculationDaysInYearType: 'Days in a Year for Interest Calculation',
                    minRequiredOpeningBalance: 'Minimum Required Opening Balance',
                    minBalanceForInterestCalculation: 'Minimum Balance for Interest Calculation',
                    minRequiredBalance: 'Minimum Required Balance',
                    lockinPeriodFrequency: 'Lock-in period',
                    lockinPeriodFrequencyType: 'Lock-in period type',
                    enforceMinRequiredBalance: 'Enforce a Minimum Balance ?',
                    allowOverdraft: 'Allow Overdraft of Savings Accounts ?',
                    withdrawalFeeForTransfers: 'Apply Withdrawal Fee for Transfers ?',
                    isDormancyTrackingActive: 'Enable Dormancy Tracking',
                    savingsReferenceAccount: 'Savings Reference Asset Account',
                    interestOnSavingsAccount: 'Interest on Savings Expense Account',
                    savingsControlAccount: 'Savings Control Liability Account',
                    settings: 'Settings',
                    createSavingProduct: 'Create Savings Product',
                    savingstransfersInSuspenseAccountHelp:
                        'An Asset account that is used a suspense account for tracking Portfolio of Loans under transfer',
                    overdraftLimit: 'Allowed Overdraft amount',
                    withHoldTaxActive: 'Attach Tax to Interest posting ?',
                    updateRunningBalances: 'Calculate Running balances',
                    savingProduct: 'Savings Product',
                    active: 'Active',
                    addSavingsApplication: 'Add Savings Applications',
                    activate: 'Activate',
                    close: 'Close',
                    withdrawalFeeType: 'Withdrawal Fee Type',
                    fieldOfficer: 'Field Officer',
                    savingsNominalAnnualInterestRateHelp:
                        'The interest rate set for savings account e.g. 5% Per year - It is always expressed as the Nominal APR',
                    savingsWithdrawalFeeTypeHelp:
                        'Indicates whether the withdrawal fee should be applied on the account for account transfers.',
                    savingApplication: 'Saving Application',
                    shareName: 'Share Name',
                    shortName: 'Short Name',
                    totalShares: 'Total Shares (should be same as Shares issued)',
                    createShareProduct: 'Create a Share Product',
                    addNewShareProduct: 'Add New Share Product',
                    updateShareProduct: 'Update Share Product',
                    shareDescription: 'Share Description',
                    unitPrice: 'Unit Price',
                    nominalShares: 'No. of Nominal Shares',
                    sharesIssued: 'Shares Issued (= Total shares)',
                    minimumShares: 'No. of Minimum Shares',
                    maximumShares: 'No. of Maximum Shares',
                    marketPricePeriods: 'Add Market Price',
                    marketPrice: 'Market Price',
                    sharesFromDate: 'Unit price effective from date',
                    minimumActivePeriodForDividends: 'Minimum Active Period for Dividends',
                    minimumActivePeriodForDividendsType: 'Minimum Active Period for Dividends Type',
                    allowDividendCalculationForInactiveClients: 'Allow dividends for inactive clients',
                    shareValue: 'Share value',
                    sharesReferenceAccount: 'Share reference',
                    shareEquity: 'Share Equity',
                    sharesSuspenseControl: 'Share Suspense control',
                    unitPriceHelp:
                        'These fields are used to define the minimum, default, maximum shares per customer',
                    nominalSharesHelp: 'Unit/Nominal Price of each share',
                    allowDividendCalculationForInactiveClientsHelp:
                        'Indicates whether the dividends can be processed even share account of this share product is inactive',
                    minimumactiveperiodFrequencyTypeHelp:
                        'Minimum active period to consider for dividend calculations',
                    accountingRuleHelp:
                        'An abstraction on top of manual Journal entires for enabling simpler data entry that defines journal entries with predefined credit and debit accounts, where either credit or debit accounts have been limited to pre-selected list of accounts or a compound journal entry where multiple debits/credits maybe amongst the set of preselected list of accounts.',
                    totalSharesHelp: 'Total number of shares that a product is offering',
                    sharesIssuedHelp:
                        "Total number of shares that an orgranization wants to issue to it's clients",
                    shareReferenceHelp:
                        'An Asset account (typically a Cash account), to which the amount is debited when new shares purchased by the account holder and credit when the account holder makes a redeem',
                    shareSuspenseHelp:
                        'A Liability account which denotes the Share deposit accounts portfolio is debited when share purchase is approved and credited when share purchase is rejected',
                    shareEquityHelp: 'Share amount will be kept in this equity account after approval',
                    shareIncomeFromFeeAccountHelp:
                        'An Income account which is credited when a fee is paid by account holder on this account',
                    marketPriceHelp: 'Add future share value with date range',
                    sharefromDateHelp: 'Date the value is effective from: Unit price effective from date',
                    shareValueHelp: 'Market Share value: Price of each share',
                    lockinPeriodFrequencyHelp:
                        'Used to indicate the length of time that a share account of this share product type is locked-in and redeems are not allowed',
                    addSavingApplication: 'Add Saving Application',
                    shareProduct: 'Share Product',
                    noOfShares: 'No of Shares',
                    addShareApplication: 'Add Share Application',
                    shareApplication: 'Shares Application',
                    defaultShares: 'Default Shares',
                    currentMarketPrice: 'Current Market Price',
                    requestedShares: 'Requested Shares',
                    minimumActivePeriod: 'Minimum Active Period',
                    minimumActivePeriodFrequencyType: 'Minimum Active Period Frequency Type',
                    clientSavingsAccount: 'Client Savings Account',
                    savingsrequestedSharesHelp: 'Total Number of Shares requested',
                    shareminimumActivePeriodHelp:
                        'Used along with Minimum Active Period Frequency Type to indicate the length of time that the share account for considering dividends. e.g. 60 Days',
                    shareminimumActivePeriodFrequencyTypeHelp:
                        'used along with Minimum Active Period to indicate the length of time that the share account for considering dividends. 0=Days e.g. 60 Days',
                    shareclientSavingsAccountHelp:
                        'The saving account in which dividend amount should be posted.',
                    shareslockinPeriodFrequencyHelp:
                        "Used along with Lock in Period Frequency Type to indicate the length of time that the shares is 'locked in' and redeems are not allowed. e.g. 6 Months",
                    generateReport: 'Generate a Report',
                    reportOption: 'Get Detailed Reports',
                    reportCategory: 'Get Reports by Category',
                    exportCSV: 'Export CSV',
                    exportPDF: 'Export PDF',
                    reportType: 'Report Type',
                    loanOfficer: 'Loan Officer',
                    loanPurpose: 'Loan Purpose',
                    branch: 'Branch',
                    obligDateType: 'Obligation Date Type',
                    GenerateReport: 'Generate Report',
                    generalSettings: 'General Settings',
                    securitySettings: 'Security Settings',
                    bulksms: 'Bulk SMS',
                    lipanampesa: 'Lipa na M-PESA',
                    domain: 'Instance Domain',
                    senderid: 'Sender Id',
                    senderUsername: 'Sender Username',
                    senderPassword: 'Sender Password',
                    nvApikey: 'Leasing API Key',
                    testShortCode: 'Sandbox Shortcode',
                    prodShortCode: 'Production Shortcode',
                    testConsumerKey: 'Sandbox Consumer Key',
                    testConsumerSecret: 'Sandbox Consumer Secret',
                    prodConsumerKey: 'Production Consumer Key',
                    prodConsumerSecret: 'Production Consumer Secret',
                    initiator: 'Initiator',
                    initiatorPassword: 'Initiator Password',
                    passKey: 'Online PassKey',
                    callbackUrl: 'Callback Link',
                    queuetimeUrl: 'Queue Timeout Link',
                    resultUrl: 'Result Link',
                    registerDevice: 'Register Device',
                    manufacturer: 'Manufacturer',
                    imei: 'IMEI Number',
                    serialNo: 'Serial Number',
                    firstLockDate: 'First Locking Date',
                    phone: 'Phone',
                    address: 'Physical Address',
                    addNewDevice: 'Register New Device',
                    deviceName: 'Device Name',
                    nextLockDate: 'Next Locking Date',
                    enrollmentStatus: 'Enrollment Status',
                    enrollmentFailureReason: 'Reason for Enrollment Failure',
                    status: 'Status',
                    devices: 'Devices in Group',
                    autolockscheduler: 'Name of Auto-Lock Scheduler',
                    deviceGroupsAddNew: 'Add New Device Group',
                    addNewSchedulerTemplate: 'Add New Scheduler Template',
                    schedulerTemplateName: 'Scheduler Template Name',
                    appliedTo: 'Applied to',
                    unlockAfterDays: 'Unlock After How many Days',
                    scheduleFrom: 'Scheduled From',
                    scheduleTo: 'Scheduled To',
                    lockDates: 'Lock Dates',
                    createAlsTemplate: 'Create Auto-Lock Scheduler Template',
                    template: 'Template',
                    schedulingMethod: 'Scheduling Method',
                    deviceGroup: 'Device Group',
                    interval: 'Interval',
                    lockLastDayMonth: 'Lock on the Last Day of the Month ?',
                    isDefault: 'Is the Default Scheduler Template ?',
                    autoLock: 'Auto-Lock Device',
                    addGroupstoTemplate: 'Add Groups to Template',
                    viewDevices: 'View Devices',
                    lockDevice: 'Lock',
                    unlockDevice: 'Unlock',
                    paymentReminderName: 'Payment Reminder Name',
                    messageText: 'Message Text',
                    messageTextPlaceHolders:
                        'Add Placeholders without last comma: %{current_date}, %{device_owner}, %{first_lock_date}, %{next_lock_date}, %{device_name}, %{imei_number}, %{serial_number}, %{device_id}, %{imei}, %{tos_link}',
                    createdAt: 'Created At',
                    updatedAt: 'Updated At',
                    addNewPaymentReminder: 'Add New Payment Reminder',
                    deviceGroups: 'Device Groups',
                    scheduledDays: 'Scheduled Days',
                    selectMultipleOptions: 'Selecting Multiple Options Allowed',
                    updatePaymentReminder: 'Update Payment Reminder',
                    updateAlsTemplate: 'Update Auto-Lock Scheduler Template',
                    smsOverview: 'SMS Overview',
                    smsCredits: 'SMS Credits',
                    noOfContacts: 'No. of Contacts',
                    account: 'Account',
                    addNewContact: 'Add New Contact',
                    templateAddNew: 'Add New Message Template',
                    identifier: 'Template Identifier',
                    notifications: 'Notifications',
                    profile: 'Profile',
                    scoreAgent: 'Score An M-PESA Agent',
                    uploadCommissionStatements: 'Upload Commission Statements',
                    uploadMPESAgents: 'Upload M-PESA Agents',
                    importAgent: 'Import M-PESA Agent',
                    reference: 'Reference',
                    interest: 'Interest',
                    fees: 'Fees',
                    disbursed: 'Disbursed',
                    disbursedAt: 'Disbursed Date',
                    expectedRepaymentAt: 'Expected Repayment Date',
                    totalOutstandingAmount: 'Total Outstanding Amount',
                    enabled: 'Enabled',
                    payouts: 'Payouts',
                    export: 'Export',
                    recentPayouts: 'Recent Payouts',
                    date: 'Created Date',
                    store: 'Store',
                    total_outstanding_amount: 'Total Outstanding Amount',
                    till: 'Till',
                    loanBalance: 'Loan Balance',
                    transactionId: 'Transaction Id',
                    transRef: 'Transaction Ref Number',
                    completedOn: 'Completed Date',
                    mobile: 'Mobile',
                    loanCycle: 'Loan Cycle',
                    score: 'Score',
                    comment: 'Comment',
                    systemStatus: 'System Status',
                    updateContact: 'Update Contact',
                    addNewMessage: 'Send SMS',
                    sendMessage: 'Send SMS Message',
                    sendSmsTo: 'Send SMS To:',
                    importContacts: 'Import Contacts',
                    uploadContacts: 'Upload Contacts',
                    importContact: 'Import Contact',
                    downloadSample: 'Download Sample',
                    addNewBulkMessage: 'Send Group SMS',
                    sendGroupMessage: 'Send Group SMS Message',
                },
            },
            sw: {
                translations: {
                    chooseLanguage: 'Chagua Lugha',
                    loginPageHeader: 'Ingia',
                    logout: 'Toka',
                    loginFormHeader: 'Ingia Kwenye akaunti yako',
                    loginForgotPassword: 'Umesahau nywila ?',
                    confirmPassword: 'Thibitisha Nenosiri',
                    tenant: 'Mpangaji',
                    username: 'Jina la mtumiaji',
                    email: 'Barua Pepe',
                    loginSignup: 'Jisajili',
                    loginRegisterNow: 'Jiandikishe Sasa!',
                    intro:
                        'Suluhisho la mwisho hadi mwisho, la kizazi kijacho la benki linalowezesha Vyama vya Mikopo, SACCOs na Benki Mamboleo kujenga uzoefu mzuri wa kibenki ambao wateja wao watapenda; kwa njia ya haraka na rahisi zaidi kuliko hapo awali.',
                    profileOffice: 'Ofisi',
                    profileCapabilities: 'Uwezo',
                    profileChangePassword: 'Badilisha Neno la Siri',
                    password: 'Neno la Siri',
                    repeatPassword: 'Thibitisha Neno la Siri',
                    Close: 'Funga',
                    SaveChanges: 'Badilisha Hifadhi',
                    Search: 'Tafuta',
                    rolesAddNew: 'Ongeza Jukumu Jipya',
                    rolesName: 'Jina la Jukumu',
                    rolesAssigned: 'Mpe Wajibu',
                    rolesDescription: 'Maelezo ya Wajibu',
                    rolesisDisabled: 'Je, Imezimwa ?',
                    rolesEnableDisable: 'Washa / Lemaza Jukumu',
                    permissions: 'Ruhusa',
                    addNewOffice: 'Ongeza Ofisi Mpya',
                    officeName: 'Jina la Ofisi',
                    openingDate: 'Tarehe la Kufungua',
                    externalID: 'Kitambulisho cha Nje',
                    hierachy: 'Utawala',
                    createOffice: 'Unda Ofisi',
                    spacesAllowed: 'Nafasi zinaruhusiwa',
                    parentOffice: 'Ofisi Kuu',
                    spacesNotAllowed: 'Nafasi haziruhusiwi',
                    details: 'Maelezo',
                    fillinAllDetails: 'Jaza maelezo yote',
                    addNewStaff: 'Ongeza Wafanyikazi wapya',
                    staffName: 'Jina la Mfanyikazi',
                    staffAssigned: 'Mpe Mfanyikazi',
                    isLoanOfficer: 'Ni Afisa wa Mkopo ?',
                    isActive: 'Je, Afisa ako Kazi ?',
                    NotActive: 'Haitumiki',
                    selfServiceAccount: 'Akaunti ya Kujihudumia',
                    createStaff: 'Ongeza Wafanyikazi',
                    fullName: 'Jina Kamili',
                    firstName: 'Jina la Kwanza',
                    lastName: 'Jina la Mwisho',
                    joiningDate: 'Tarehe ya Kujiunga',
                    mobileNumber: 'Namba ya simu ya mkononi',
                    groupsName: 'Jina la Kikundi',
                    groupAccountName: 'Namba la Akaunti ya Kikundi',
                    groupLoanOfficer: 'Afisa wa Mikopo wa Kikundi',
                    groupSubmittedDate: 'Tarehe ya Kuundwa kwa Kikundi',
                    groupsAddNew: 'Ongeza Kikundi Kipya',
                    client: 'Mteja',
                    clients: 'Wateja',
                    activationDate: 'Tarehe ya Kuanzishwa kwa Mwanachama',
                    officeJoiningDate: 'Tarehe ya Kujiunga na Ofisi',
                    isStaff: 'Ni Mfanyikazi ?',
                    groupClientName: 'Jina la Mteja wa Kikundi',
                    groupClientAccountName: 'Akaunti la Mteja wa Kikundi',
                    createUser: 'Unda Mtumiaji Mpya',
                    addNewUser: 'Ongeza Mtumiaji Mpya',
                    update: 'Sasisha',
                    profileChangePasswordfor: 'Badilisha Nenosiri la ',
                    addClient2Group: 'Ongeza Mteja Kwa Kikundi',
                    addNewClient: 'Ongeza Mteja Mpya',
                    clientName: 'Jina la Mteja',
                    clientAccountName: 'Namba la Akaunti ya Mteja',
                    createClient: 'Unda Mteja Mpya',
                    assignStaff: 'Mpe Mfanyakazi',
                    assign: 'Mpe',
                    updateCurrency: 'Sasisha Sarafu Inayoruhusiwa',
                    currencyName: 'Jina la Sarafu',
                    currencyDisplayName: 'Jina la Kuonyesha Sarafu',
                    currencyNameCode: 'Msimbo wa Jina la Sarafu',
                    currencyDecimalPlaces: 'Maeneo ya Nukta la Sarafu',
                    paymentTypeName: 'Jina la Aina ya Malipo',
                    isCashPayment: 'ni Malipo ya Fedha?',
                    position: 'Nafasi',
                    delete: 'Futa',
                    addNewPaymentType: 'Ongeza Aina Mpya ya Malipo',
                    updatePaymentType: 'Sasisha Aina ya Malipo:',
                    addNewGL: 'Ongeza Akaunti Mpya ya Leja Kuu',
                    glAccountName: 'Jina la Akaunti ya Leja ya Jumla',
                    glCode: 'Kanuni ya Leja ya Jumla',
                    manualEntriesAllowed: 'Maingizo Mwongozo Yanaruhusiwa',
                    accountUsage: 'Matumizi ya Akaunti',
                    isglActive: 'Je, GL Inatumika?',
                    createGL: 'Tengeneza Leja ya Jumla',
                    glName: 'Jina la Akaunti ya GL',
                    glDescription: 'Maelezo ya GL',
                    gledgerType: 'Aina ya GL',
                    glUsage: 'Matumizi ya Akaunti ya GL',
                    isManualEntryAllowed: 'Je, Uingizaji wa Mikono Unaruhusiwa?',
                    Asset: 'Mali',
                    Liability: 'Dhima',
                    Equity: 'Usawa',
                    Income: 'Mapato',
                    Expense: 'Gharama',
                    detail: 'Maelezo',
                    Header: 'Kijajuu',
                    accountingRuleName: 'Jina la Sheria ya Uhasibu',
                    debitTags: 'Lebo za Debit',
                    creditTags: 'Lebo za Mikopo',
                    addNewAccountingRule: 'Ongeza Sheria Mpya ya Uhasibu',
                    accountingRule: 'Kanuni ya Uhasibu',
                    updateAccountingRule: 'Sasisha Sheria ya Uhasibu',
                    Description: 'Maelezo',
                    accountToDedit: 'Akaunti ya Debit',
                    accountToCredit: 'Akaunti ya Deni',
                    addFund: 'Ongeza Mfuko',
                    fundName: 'Jina la Mfuko',
                    updateFund: 'Sasisha Mfuko',
                    addFloatingRate: 'Ongeza Kiwango cha Kuelea',
                    floatingRateName: 'Jina la Kiwango cha Kuelea',
                    isBaseLendingRate: 'ni Kiwango cha Msingi cha Ukopeshaji ?',
                    createdBy: 'Imetengenezwa na',
                    createdOn: 'Tarehe Iliyoundwa',
                    modifiedBy: 'Imebadilishwa na',
                    modifiedOn: 'Tarehe Iliyorekebishwa',
                    adjust: 'Rekebisha',
                    updateFR: 'Sasisha Kiwango cha Kuelea',
                    ratePeriod: 'Kiwango cha Kipindi',
                    updateFRPeriod: 'Sasisha Kiwango cha Kuelea',
                    interestRate: 'Kiwango cha Riba (%)',
                    interestMethod: 'Mbinu ya Maslahi',
                    transactionID: 'Kitambulisho cha Muamala',
                    accountName: 'Jina la Akaunti',
                    transactionDate: 'Tarehe ya Muamala',
                    entityType: 'Aina ya Huluki',
                    isreversed: 'imebadilishwa?',
                    action: 'Kitendo',
                    remove: 'Toa',
                    addNewJE: 'Ongeza Ingizo Jipya la Jarida',
                    reverse: 'Geuza',
                    ismanualEntry: 'ni Mwongozo wa Kuingia?',
                    addNewJournalEntry: 'Ongeza Ingizo Jipya la Jarida Lililosawazishwa',
                    creditAmount: 'Kiasi cha Mkopo',
                    debitAmount: 'Kiasi cha Mfuko',
                    accountNo: 'Nambari ya Akaunti',
                    chequeNo: 'Nambari ya Cheki',
                    receiptNo: 'Nambari ya Risiti',
                    bankNo: 'Namabari ya Benki',
                    routingCode: 'Msimbo wa Njia',
                    description: 'Maelezo',
                    paymentType: 'Aina ya malipo',
                    currency: 'Sarafu',
                    taxComponent: 'Sehemu ya Ushuru',
                    taxComponent1: 'Sehemu ya Ushuru 2',
                    addTaxComponent: 'Ongeza Sehemu ya Ushuru',
                    taxcomponentName: 'Jina la Sehemu ya Ushuru',
                    updateTaxComponent: 'Sasisha Sehemu ya Ushuru',
                    percentage: 'Asilimia (%)',
                    creditAccountType: 'Aina ya Akaunti ya Mkopo',
                    creditAccountId: 'Kitambulisho cha Akaunti ya Mkopo',
                    startDate: 'Tarehe ya Kuanza',
                    endDate: 'Tarehe ya Mwisho',
                    addNewTaxComponent: 'Ongeza Sehemu Mpya ya Ushuru',
                    creditAccount: 'Akaunti ya Mkopo',
                    updateTaxGroup: 'Sasisha Kikundi cha Ushuru',
                    taxgroupName: 'Jina la Kikundi cha Ushuru',
                    taxGroup: 'Kikundi cha Ushuru',
                    addNewTaxGroup: 'Ongeza Kikundi Kipya cha Ushuru',
                    view: 'Tazama',
                    createCharge: 'Unda Malipo',
                    addNewCharge: 'Ongeza Malipo Mpya',
                    charges: 'Malipo',
                    chargeAppliesTo: 'Malipo Hutumika Kwa',
                    chargePaymentMode: 'Njia ya Malipo',
                    chargeName: 'Jina la Malipo',
                    chargeTimeType: 'Aina ya Muda wa Kuchaji',
                    chargeCalculationType: 'Aina ya Kukokotoa Malipo',
                    minCap: 'Kikomo cha Chini',
                    maxCap: 'Upeo wa Kikomo',
                    feeFrequency: 'Mzunguko wa Ada',
                    feeOnMonthDay: 'Ada kwa Siku ya Mwezi',
                    feeInterval: 'Muda wa Ada',
                    amount: 'Kiasi',
                    TaxGroup: 'Kikundi cha Ushuru',
                    isPenalty: 'ni Adhabu',
                    chargeChoices:
                        'Hesabu kwa ajili ya kuonyesha kama malipo yatatumika kwa mikopo au wateja',
                    chargeCalculationChoices:
                        'Hesabu ya kuonyesha kama kiasi cha malipo ni bapa au asilimia ambapo "% ya Kiasi" cha Mikopo inarejelea mhusika mkuu wa mkopo aliyetolewa.',
                    chargeTimeTypeChoices:
                        'Kwa kuweka tarehe ya malipo ya mara kwa mara "Ada ya Siku ya Mwezi" weka aina ya malipo ya mara kwa mara kuwa ya Kila Mwezi au ya Mwaka.',
                    chargeMinCapChoices:
                        'Inatumika kutekeleza kiwango cha chini cha malipo katika hali ambapo kiasi kilichohesabiwa ni chini ya kiwango cha chini kilichotolewa',
                    chargeMaxCapChoices:
                        'Hutumika kutekeleza kiwango cha juu cha malipo katika hali ambapo kiasi kilichohesabiwa ni kikubwa kuliko kiwango cha juu kilichotolewa',
                    updateCharge: 'Sasisha Malipo',
                    createLoanProduct: 'Tengeneza Bidhaa ya Mkopo',
                    updateLoanProduct: 'Sasisha Bidhaa ya Mkopo',
                    mandatoryDetails: 'Maelezo ya lazima',
                    productTerms: 'Masharti ya Bidhaa',
                    productSettings: 'Mipangilio ya Bidhaa',
                    accounting: 'Uhasibu',
                    productName: 'Jina la Bidhaa',
                    productShortName: 'Jina fupi la Bidhaa',
                    fund: 'Mfuko',
                    includeinCustomerCounter: 'Jumuisha katika Kaunta ya Wateja',
                    closeDate: 'Tarehe la Kufunga Deni',
                    digitsAfterDecimal: 'Nambari Baada ya Desimali',
                    inMultiplesOf: 'Sarafu Katika Nyingi za',
                    installmentsinMultipleOf: 'Mikopo katika Nyingi za',
                    Principal: 'Chaguomsingi',
                    defaultPrincipal: 'Mkuu Chaguomsingi',
                    minPrincipal: 'Kima cha chini cha Mkuu',
                    maxPrincipal: 'Upeo Mkuu',
                    defaultNoOfRepayments: 'Nambari Chaguomsingi ya Marejesho ya Mkopo',
                    noOfRepayments: 'Nambari ya Marejesho ya Mkopo',
                    repaymentEvery: 'Rejesha Mkopo Kila',
                    repaymentFrequencyType: 'Aina ya Marudio ya Urejeshaji',
                    interestRates: 'Viwango vya riba',
                    isLinkedtoFLoatingInterestRates: 'Inahusishwa na Viwango vya Riba vinavyoelea?',
                    interestRatePerPeriod: 'Kiwango cha Riba kwa Kipindi',
                    interestRateFrequencyType: 'Aina ya Marudio ya Kiwango cha Riba',
                    interestType: 'Aina ya riba',
                    interestCalculationPeriodType: 'Aina ya Kipindi cha Maslahi',
                    interestCalculationPeriod: 'Kipindi cha Kuhesabu Riba',
                    amortizationType: 'Aina ya Malipo',
                    repaymentStrategy: 'Mkakati wa Uchakataji wa Shughuli',
                    inArrearsTolerance: 'katika Uvumilivu wa Madeni',
                    minimumDaysBetweenDisbursalAndFirstRepayment:
                        'Siku za Chini Kati ya Utoaji na Urejeshaji wa Kwanza',
                    principalThresholdForLastInstalment: 'Kiwango Cha Msingi cha Awamu ya Mwisho',
                    graceOnPrincipalPayment: 'Neema kwenye Malipo Mkuu',
                    graceOnInterestPayment: 'Neema juu ya Malipo ya Riba',
                    graceOnInterestCharged: 'Neema kwa Riba Kutozwa',
                    graceOnArrearsAgeing: 'Neema juu ya Madeni ya Kuzeeka',
                    overdueDaysForNPA: 'Siku Zilizochelewa kwa NPA',
                    daysInYearType: 'Siku katika Aina ya Mwaka',
                    daysInMonthType: 'Siku katika Aina ya Mwezi',
                    InterestRecalculation: 'Uhesabuji wa Maslahi',
                    interestRecalculationCompoundingMethod: 'Mbinu ya Kuchanganya Maslahi',
                    rescheduleStrategyMethod: 'Panga upya Mbinu ya Mkakati',
                    recalculationCompoundingFrequencyType: 'Aina ya Marudio Yanayojumuisha Marudio',
                    recalculationCompoundingFrequencyInterval:
                        'Uhesabuji Upya Unaojumuisha Muda wa Mara kwa Mara',
                    recalculationCompoundingFrequencyDate: 'Tarehe ya Kuhesabu Upya ya Marudio',
                    recalculationRestFrequencyType: 'Aina ya Marudio ya Marudio ya Kupumzika',
                    recalculationRestFrequencyInterval: 'Kipindi cha Marudio ya Marudio ya Kupumzika',
                    recalculationRestFrequencyDate: 'Tarehe ya Kuhesabu upya Masafa ya Kupumzika',
                    preClosureInterestCalculationStrategy: 'Mkakati wa Kukokotoa Maslahi kabla ya Kufungwa',
                    isArrearsBasedOnOriginalSchedule: 'ni Madeni Kulingana na Ratiba Halisi',
                    guaranteeRequirements: 'Mahitaji ya Dhamana',
                    placeGuaranteeFundsonHold: 'Weka Pesa za Dhamana Zilizopo',
                    mandatoryGuarantee: 'Dhamana ya lazima',
                    minimumGuaranteeFromOwnFunds: 'Kiwango cha Chini cha Dhamana Kutoka kwa Fedha Zake',
                    minimumGuaranteeFromGuarantor: 'Kiwango cha Chini cha Dhamana Kutoka kwa Mdhamini',
                    allowAttributeOverrides: 'Ruhusu Ubatilishaji wa Sifa',
                    isInterestRecalculationEnabled: 'Je, Ukadiriaji Upya wa Riba Umewezeshwa?',
                    charge: 'Malipo/Chaji',
                    accountingRules: 'Kanuni za Uhasibu',
                    fundSource: 'Chanzo cha Mfuko',
                    loanPortfolio: 'Kwingineko ya Mkopo',
                    transfersInSuspenseAccount: 'Uhamisho katika Akaunti ya Mashaka',
                    receivableInterestAccount: 'Akaunti ya Riba Inayopokelewa',
                    receivableFeeAccount: 'Akaunti ya Ada Inayopatikana',
                    incomeFromFeeAccount: 'Mapato kutoka kwa Akaunti ya Ada',
                    incomeFromPenaltyAccount: 'Mapato kutoka kwa Akaunti ya Adhabu',
                    incomeFromRecoveryAccount: 'Mapato kutoka kwa Akaunti ya Urejeshaji',
                    interestOnLoanAccount: 'Riba kwenye Akaunti ya Mkopo',
                    writeOffAccount: 'Akaunti ya Kufuta',
                    overpaymentLiabilityAccount: 'Akaunti ya Dhima ya Kulipa Zaidi',
                    receivablePenaltyAccount: 'Akaunti ya Adhabu Inayopokelewa',
                    chargeChoices:
                        'Hesabu kwa ajili ya kuonyesha kama malipo yatatumika kwa mikopo au wateja',
                    chargeCalculationChoices:
                        'Hesabu ya kuonyesha kama kiasi cha malipo ni bapa au asilimia ambapo "% ya Kiasi" cha Mikopo inarejelea mhusika mkuu wa mkopo aliyetolewa.',
                    chargeTimeTypeChoices:
                        'Kwa kuweka tarehe ya malipo ya mara kwa mara "Ada ya Siku ya Mwezi" weka aina ya malipo ya mara kwa mara kuwa ya Kila Mwezi au ya Mwaka.',
                    chargeMinCapChoices:
                        'Inatumika kutekeleza kiwango cha chini cha malipo katika hali ambapo kiasi kilichohesabiwa ni chini ya kiwango cha chini kilichotolewa',
                    chargeMaxCapChoices:
                        'Hutumika kutekeleza kiwango cha juu cha malipo katika hali ambapo kiasi kilichohesabiwa ni kikubwa kuliko kiwango cha juu kilichotolewa',
                    updateCharge: 'Sasisha Malipo',
                    inMultiplesOfHelp:
                        'Batilisha thamani chaguo-msingi ya sarafu inayozungusha hadi fungu la thamani iliyotolewa. Kwa mfano, ukiweka "multiples of 100", thamani ya sarafu itapunguzwa hadi 200, 300, 400, nk.',
                    installmentsinMultipleOfHelp:
                        'Batilisha thamani chaguo-msingi ya kuzungusha kiasi cha awamu hadi vizidishio vya thamani iliyotolewa. Kwa mfano: 10, kwa hivyo kiasi cha Malipo hupunguzwa hadi sekunde 10.',
                    amortizationTypeHelp:
                        'Kulingana na aina ya malipo unayofanya, chagua moja. Malipo Sawa - Kiasi chote cha ulipaji kitakuwa sawa lakini kiasi kikuu na cha faida kitatofautiana kwa kila malipo. Au Malipo Sawa ya Msingi - Kiasi zote kuu zitakuwa sawa lakini kiasi cha malipo na riba kitatofautiana kwa kila malipo.',
                    transactionProcessingStrategyHelp:
                        'Mkakati wa ulipaji huamua mlolongo ambao kila sehemu hulipwa. Mikakati ifuatayo inaungwa mkono: ',
                    MifostransactionProcessingStrategyHelp:
                        'Mkakati wa Mifos => Adhabu zilizopitwa na wakati, ada zilizopitwa na wakati, riba iliyochelewa na inayostahili, Muhimu na malipo yanayostahili. ',
                    PIPFOtransactionProcessingStrategyHelp:
                        'Agizo la Ada ya Adhabu ya Riba Kuu => Mkuu wa shule uliopitwa na wakati, riba iliyochelewa na inayostahili, adhabu zilizopitwa na wakati na zinazodaiwa, ada zilizochelewa na zinazodaiwa',
                    IPPFOtransactionProcessingStrategyHelp:
                        'Agizo la Ada ya Adhabu Kuu za Riba => Riba iliyopitwa na wakati, Mkuu wa shule iliyochelewa na inayodaiwa, Adhabu zilizopitwa na wakati na zinazopaswa kulipwa, ada zilizochelewa na zinazodaiwa',
                    RBItransactionProcessingStrategyHelp:
                        'RBI (India) => Kwa mujibu wa kanuni za RBI, yote yaliyochelewa yanapaswa kulipwa, basi tu ndipo kiasi kinachohitajika kinaweza kukusanywa. Wakati kiasi kilichochelewa kinalipwa, amri ya malipo itakuwa adhabu zilizochelewa, ada zilizochelewa, mkuu wa muda. Tu baada ya malipo ya muda uliochelewa, malipo yanakusanywa na amri ya malipo itakuwa ada zinazostahili, riba inayostahili na mkuu anayestahili.',
                    inArrearsToleranceHelp:
                        'Kiasi ambacho kinaweza "kuondolewa" mwishoni mwa malipo yote ya mkopo kwa sababu ni kidogo sana kuhangaikia. Hiki pia ni kiasi cha uvumilivu kinachotathminiwa wakati wa kubaini kama mkopo unadaiwa.',
                    principalThresholdForLastInstalmentHelp:
                        'Sehemu inawakilisha asilimia ya kiasi kikuu cha malipo ya sasa kwa kulinganisha dhidi ya salio kuu ili kuongeza malipo mengine ya ulipaji. Ikiwa kiasi kuu kinachosalia ni kidogo kuliko kiasi kilichohesabiwa, kiasi kinachobakia ambacho hakijalipwa kitaongezwa kwenye malipo ya sasa. Thamani chaguomsingi ya mkopo wa malipo mengi ni 50% na mkopo usio na malipo mengi ni 0% ',
                    graceOnPrincipalPaymentHelp:
                        'Inawakilisha idadi ya vipindi vya ulipaji ambavyo neema inapaswa kutumika kwa kipengele kikuu cha kipindi cha ulipaji. ',
                    graceOnInterestPaymentHelp:
                        'Inawakilisha idadi ya vipindi vya urejeshaji ambavyo neema inapaswa kutumika kwa sehemu ya riba ya kipindi cha urejeshaji. Bado riba inahesabiwa lakini inarekebishwa kwa vipindi vya malipo vya baadaye.',
                    graceOnInterestChargedHelp:
                        'Inawakilisha idadi ya vipindi vya urejeshaji ambavyo vinapaswa kuwa bila riba.',
                    graceOnArrearsAgeingHelp:
                        'Inatumika katika hesabu ya Malimbikizo ili kuzingatia tu mikopo ambayo ni zaidi ya siku za graceOnArrearsAgeing ambazo zimechelewa.',
                    overdueDaysForNPAHelp:
                        'Inawakilisha idadi ya juu zaidi ya siku ambazo Mkopo unaweza kucheleweshwa kabla ya kuainishwa kama NPA (mali isiyotekelezwa)',
                    loanproductAddNew: 'Ongeza Bidhaa Mpya ya Mkopo',
                    loanName: 'Jina la Deni',
                    loanProduct: 'Bidhaa ya Mkopo',
                    applyLoan: 'Omba Mkopo Mpya',
                    principal: 'Mkurugenzi',
                    addNewLoan: 'Ongeza Mkopo Mpya',
                    submittedOnDate: 'Imewasilishwa Tarehe',
                    submittedByUsername: 'Imewasilishwa na Jina la mtumiaji',
                    expectedMaturityDate: 'Tarehe ya Kukomaa inayotarajiwa',
                    approval: 'Ruhusa',
                    summary: 'Muhtasari',
                    terms: 'Masharti',
                    transactionProcessingStrategy: 'Mkakati wa Uchakataji wa Shughuli',
                    loanType: 'Aina ya Deni',
                    expectedDisbursementDate: 'Tarehe inayotarajiwa ya Utoaji',
                    loanTermFrequency: 'Muda wa Muda wa Mkopo',
                    loanTermFrequencyType: 'Aina ya Muda wa Muda wa Mkopo',
                    loanApplication: 'Ombi la Mkopo',
                    loanApplicationHelp:
                        'Anza maombi ya mkopo kwa kuchagua kwanza `Aina ya Mkopo`, `Bidhaa ya Mkopo`, `Kundi` AU`Mteja`. Kisha ubofye `muhtasari` ili kuthibitisha maelezo ya ombi lako kabla ya kuwasilisha.',
                    lengthofLoanTerm: 'Muda wa Mkopo',
                    disburseLoan: 'Disburse Loan',
                    transactionAmount: 'Kiasi cha Muamala',
                    actualDisbursementDate: 'Tarehe Halisi ya Utoaji',
                    accountNumber: 'Nambari ya Akaunti',
                    checkNumber: 'Nambari ya Cheki',
                    receiptNumber: 'Namba ya Risiti',
                    bankNumber: 'Nambari ya Benki',
                    pendingApproval: 'Inasubiri Kuidhinishwa',
                    approved: 'Imeidhinishwa',
                    approve: 'Idhinisha',
                    disburse: 'Lipa',
                    approveLoan: 'Idhinisha Mkopo',
                    approvedAmount: 'Kiasi Kilichoidhinishwa',
                    approvedRejectedDate: 'Tarehe Iliyoidhinishwa / Iliyokataliwa',
                    approvedRejected: 'Imeidhinishwa / Imekataliwa',
                    Reject: 'Imekataliwa',
                    Approve: 'Imekubaliwa',
                    reasonRejection: 'Sababu ya Kukataa',
                    nominalAnnualInterestRate: 'Kiwango cha Riba cha Kila Mwaka',
                    savingproductAddNew: 'Ongeza Bidhaa Mpya ya Akiba',
                    savingName: 'Jina la Akiba',
                    nominalAnnualInterestRate: 'Kiwango cha Riba cha Kila Mwaka',
                    savingproductAddNew: 'Ongeza Bidhaa Mpya ya Akiba',
                    savingName: 'Jina la Akiba',
                    savingsInterestRateHelp:
                        'Kiwango cha riba chaguo-msingi huwekwa wakati wa kuunda akaunti za akiba',
                    savingsInterestCompoundingPeriodTypeHelp:
                        'Kipindi ambacho kiwango cha riba kinajumuishwa',
                    savingsinterestPostingPeriodTypeHelp:
                        'Kipindi ambacho kiwango cha riba kinatumwa au kuwekwa kwenye akaunti ya akiba',
                    savingsinterestCalculationTypeHelp: 'Mbinu inayotumika kukokotoa riba',
                    savingsinterestCalculationDaysInYearTypeHelp:
                        'Mpangilio wa idadi ya siku katika mwaka za kutumia kukokotoa riba',
                    savingsminRequiredOpeningBalanceHelp:
                        'Huweka kiwango cha chini zaidi cha amana kinachohitajika ili kufungua akaunti ya akiba ya aina hii ya bidhaa ya kuokoa',
                    savingsminBalanceForInterestCalculationHelp:
                        'Huweka salio linalohitajika kwa ajili ya kukokotoa riba',
                    savingsminRequiredBalanceHelp:
                        'Huweka salio la chini linaloruhusiwa kwa akaunti ya kuhifadhi',
                    savingslockinPeriodFrequencyHelp:
                        'Hutumika kuonyesha urefu wa muda ambao akaunti ya akiba ya aina hii ya bidhaa ya akiba imefungiwa ndani na uondoaji hauruhusiwi.',
                    savingsEnforceMinRequiredBalanceHelp:
                        'Tekeleza Salio la Kiwango cha Chini Inayohitajika: Inaonyesha kama itatekeleza salio la chini zaidi',
                    savingsOverdraftAllowedHelp:
                        'Overdrafti Inaruhusiwa: Inaonyesha kama kuhifadhi akaunti kulingana na bidhaa hii ya kuokoa kunaweza kuwa na overdraft',
                    savingsDormancyTrackingHelp:
                        'Ufuatiliaji wa Kulala: Huwasha ufafanuzi na kufuatilia Akaunti za Akiba ambazo hazitumiki',
                    savingsReferenceAccountHelp:
                        'Akaunti ya Mali ambayo inatumika akaunti ya mashaka kufuatilia Malipo ya Mikopo chini ya uhamisho',
                    savingsinterestOnSavingsAccountHelp:
                        'Akaunti ya Gharama, ambayo hutozwa wakati riba inapaswa kulipwa kwa mteja',
                    savingsControlAccountHelp:
                        'Akaunti ya dhima ambayo inaashiria kwingineko ya akaunti za amana za Akiba na huwekwa rehani wakati amana inapowekwa na kutolewa wakati uondoaji unafanywa.',
                    savingsincomeFromFeeAccountHelp:
                        'Akaunti ya Mapato ambayo huwekwa wakati ada inalipwa na mwenye akaunti kwenye akaunti hii',
                    savingsincomeFromPenaltyAccountHelp:
                        'Akaunti ya Mapato, ambayo hutolewa wakati adhabu inalipwa na mwenye akaunti kwenye akaunti hii',
                    savingWithHoldTaxHelp:
                        'Kodi ya Kuzuia: Alama ya boolean ya kuambatisha ushuru kwa uchapishaji wa faida',
                    interestCompoundingPeriodType: 'Kipindi cha Kuchanganya Maslahi',
                    interestPostingPeriodType: 'Kipindi cha Kuchapisha Riba',
                    interestCalculationType: 'Maslahi Yamehesabiwa Kwa Kutumia ?',
                    interestCalculationDaysInYearType: 'Siku Katika Mwaka kwa Kukokotoa Riba',
                    minRequiredOpeningBalance: 'Kiwango cha Chini Kinachohitajika Salio la Ufunguzi',
                    minBalanceForInterestCalculation: 'Salio la Chini la Kukokotoa Riba',
                    minRequiredBalance: 'Salio la Chini linalohitajika',
                    lockinPeriodFrequency: 'Kipindi cha kufunga',
                    lockinPeriodFrequencyType: 'Aina ya kipindi cha kujifungia',
                    enforceMinRequiredBalance: 'Tekeleza Salio la Kima cha Chini ?',
                    allowOverdraft: 'Ruhusu Akaunti za Akiba ?',
                    withdrawalFeeForTransfers: 'Omba Ada ya Kutoa kwa Uhamisho ?',
                    isDormancyTrackingActive: 'Washa Ufuatiliaji Usiosinzia ?',
                    savingsReferenceAccount: 'Akaunti ya Mali ya Marejeleo ya Akiba',
                    interestOnSavingsAccount: 'Riba kwenye Akaunti ya Gharama za Akiba',
                    savingsControlAccount: 'Akaunti ya Dhima ya Kudhibiti Akiba',
                    settings: 'Mipangilio',
                    createSavingProduct: 'Unda Bidhaa ya Kuokoa',
                    savingstransfersInSuspenseAccountHelp:
                        'Akaunti ya Mali ambayo inatumika akaunti ya mashaka kufuatilia Malipo ya Mikopo chini ya uhamisho',
                    overdraftLimit: 'Kiasi kinachoruhusiwa cha Overdrafti',
                    withHoldTaxActive: 'Ambatanisha Kodi kwenye uchapishaji wa Faida ?',
                    updateRunningBalances: 'Kuhesabu Mizani ya Kuendesha',
                    savingProduct: 'Bidhaa ya Akiba',
                    active: 'Inayotumika',
                    addSavingsApplication: 'Ongeza Maombi ya Kuokoa',
                    activate: 'Amilisha',
                    close: 'Funga',
                    withdrawalFeeType: 'Aina ya Ada ya Uondoaji',
                    fieldOfficer: 'Afisa Shamba',
                    savingsNominalAnnualInterestRateHelp:
                        'Kiwango cha riba kilichowekwa kwa akaunti ya akiba k.m. 5% Kwa mwaka - Daima huonyeshwa kama APR ya Jina',
                    savingsWithdrawalFeeTypeHelp:
                        'Inaonyesha kama ada ya uondoaji inapaswa kutumika kwenye akaunti kwa uhamisho wa akaunti.',
                    savingApplication: 'Kuhifadhi Maombi',
                    shareProduct: 'Shiriki Bidhaa',
                    noOfShares: 'Idadi ya Hisa',
                    addShareApplication: 'Ongeza Programu ya Kushiriki',
                    addSavingApplication: 'Add Saving Application',
                    shareProduct: 'Share Product',
                    noOfShares: 'No of Shares',
                    addShareApplication: 'Ongeza Programu ya Kushiriki',
                    shareApplication: 'Maombi ya Hisa',
                    defaultShares: 'Hisa Chaguomsingi',
                    currentMarketPrice: 'Bei ya Soko la Sasa',
                    requestedShares: 'Ulizoomba Hisa',
                    minimumActivePeriod: 'Kipindi cha Amilifu cha Chini',
                    minimumActivePeriodFrequencyType: 'Aina ya Marudio ya Kipindi Amilifu cha Chini',
                    clientSavingsAccount: 'Akaunti ya Akiba ya Mteja',
                    savingsrequestedSharesHelp: 'Jumla ya Idadi ya Hisa zilizoombwa',
                    shareminimumActivePeriodHelp:
                        'Inatumika pamoja na Aina ya Kiwango cha Chini cha Muda wa Kutumika ili kuonyesha urefu wa muda ambao akaunti ya hisa inazingatia gawio. k.m. Siku 60',
                    shareminimumActivePeriodFrequencyTypeHelp:
                        'hutumika pamoja na Kipindi cha Kima cha chini cha Utendaji ili kuonyesha urefu wa muda ambao akaunti ya hisa inazingatia gawio. 0=Siku k.m. Siku 60',
                    shareclientSavingsAccountHelp:
                        'Akaunti ya akiba ambayo kiasi cha gawio kinapaswa kutumwa.',
                    shareslockinPeriodFrequencyHelp:
                        "Inatumika pamoja na Aina ya Marudio ya Lock in Period ili kuonyesha urefu wa muda ambao hisa 'zimefungwa' na haziruhusiwi kukomboa. k.m. miezi 6",
                    generateReport: 'Tengeneza Ripoti',
                    reportOption: 'Pata Ripoti za Kina',
                    reportCategory: 'Pata Ripoti kwa Kitengo',
                    exportCSV: 'Hamisha CSV',
                    exportPDF: 'Hamisha PDF',
                    reportType: 'Aina ya Ripoti',
                    loanOfficer: 'Afisa Mikopo',
                    loanPurpose: 'Madhumuni ya Mkopo',
                    branch: 'Tawi',
                    obligDateType: 'Aina ya Tarehe ya Wajibu',
                    GenerateReport: 'Tengeneza Ripoti',
                    generalSettings: 'Mipangilio ya Jumla',
                    securitySettings: 'Mipangilio ya Usalama',
                    bulksms: 'SMS nyingi',
                    lipanampesa: 'Lipa na M-PESA',
                    domain: 'Domain',
                    senderid: 'Kitambulisho cha Mtumaji',
                    senderUsername: 'Jina la Mtumaji',
                    senderPassword: 'Nenosiri la Mtumaji',
                    nvApikey: 'Ufunguo wa API wa Kukodisha',
                    testShortCode: 'Njia fupi ya Sandbox',
                    prodShortCode: 'Njia fupi ya Production',
                    testConsumerKey: 'Sandbox Consumer Key',
                    testConsumerSecret: 'Sandbox Consumer Secret',
                    prodConsumerKey: 'Production Consumer Key',
                    prodConsumerSecret: 'Production Consumer Secret',
                    initiator: 'Mwanzilishi',
                    initiatorPassword: 'Neno Siri la Mwanzilishi',
                    passKey: 'Online PassKey',
                    callbackUrl: 'Callback Link',
                    queuetimeUrl: 'Queue Timeout Link',
                    resultUrl: 'Result Link',
                    registerDevice: 'Register Device',
                    manufacturer: 'Mtengenezaji',
                    imei: 'Nambari ya IMEI',
                    serialNo: 'Nambari ya Ufuatiliaji',
                    firstLockDate: 'Tarehe ya Kwanza ya Kufunga',
                    phone: 'Nambari ya Simu',
                    address: 'Anwani ya Mahali ulipo',
                    addNewDevice: 'Sajili Kifaa Kipya',
                    deviceName: 'Jina la Kifaa',
                    nextLockDate: 'Tarehe Inayofuata ya Kufunga',
                    enrollmentStatus: 'Hali ya Uandikishaji',
                    enrollmentFailureReason: 'Sababu ya Kushindwa Kujiandikisha',
                    status: 'Hali',
                    devices: 'Vifaa kwenye Kikundi',
                    autolockscheduler: 'Jina la Kiratibu cha Kufunga Kiotomatiki',
                    deviceGroupsAddNew: 'Ongeza Kikundi Kipya cha Kifaa',
                    addNewSchedulerTemplate: 'Ongeza Kiolezo Kipya cha Kiratibu',
                    schedulerTemplateName: 'Jina la Kiolezo cha Mratibu',
                    appliedTo: 'Imetumika kwa',
                    unlockAfterDays: 'Fungua Baada ya Siku Ngapi',
                    scheduleFrom: 'Imepangwa Kutoka',
                    scheduleTo: 'Imepangwa Mpaka',
                    lockDates: 'Tarehe za Kufunga Simu',
                    createAlsTemplate: 'Unda Kiolezo cha Kiratibu cha Kufunga Kiotomatiki',
                    template: 'Kiolezo',
                    schedulingMethod: 'Mbinu ya Kupanga',
                    deviceGroup: 'Kikundi cha Kifaa',
                    interval: 'Muda',
                    lockLastDayMonth: 'Je, ungependa kufunga Kifaa Siku ya Mwisho ya Mwezi?',
                    isDefault: 'Je, Kiolezo cha Kiratibu Chaguomsingi ?',
                    autoLock: 'Kufunga Kifaa Kiotomatiki',
                    addGroupstoTemplate: 'Ongeza Vikundi kwenye Kiolezo',
                    viewDevices: 'Angalia Vifaa',
                    addDevices: 'Ongeza Vifaa',
                    lockDevice: 'Funga',
                    unlockDevice: 'Fungua',
                    paymentReminderName: 'Jina la Kikumbusho la Malipo',
                    messageText: 'Maandishi ya Ujumbe',
                    createdAt: 'Imeundwa Katika',
                    updatedAt: 'Imesasishwa Katika',
                    addNewPaymentReminder: 'Ongeza Kikumbusho Kipya cha Malipo',
                    deviceGroups: 'Vikundi vya Kifaa',
                    scheduledDays: 'Siku Zilizopangwa',
                    selectMultipleOptions: 'Kuchagua Chaguzi Nyingi Zinaruhusiwa',
                    updatePaymentReminder: 'Sasisha Kikumbusho cha Malipo',
                    updateAlsTemplate: 'Sasisha Kiolezo cha Kiratibu cha Kufunga Kiotomatiki',
                    smsOverview: 'Muhtasari wa SMS',
                    smsCredits: 'Mikopo ya SMS',
                    noOfContacts: 'Idadi ya Anwani',
                    account: 'Akaunti',
                    addNewContact: 'Ongeza Anwani Mpya',
                    templateAddNew: 'Ongeza Kiolezo Kipya',
                    identifier: 'Kitambulisho cha Kiolezo',
                    notifications: 'Arifa',
                    profile: 'Wasifu',
                    scoreAgent: 'Alama Wakala wa M-PESA',
                    uploadCommissionStatements: 'Pakia Taarifa za Tume',
                    uploadMPESAgents: 'Pakia Mawakala wa M-PESA',
                    importAgent: 'Kuagiza Mawakala wa M-PESA',
                    reference: 'Kumbukumbu',
                    interest: 'Riba',
                    fees: 'Ada',
                    disbursed: 'Kiasi Kilichotolewa',
                    disbursedAt: 'Tarehe ya Kutolewa Mkopo',
                    totalOutstandingAmount: 'Jumla ya Kiasi Kinachodaiwa',
                    expectedRepaymentAt: 'Tarehe ya Kulipa Inatarajiwa',
                    enabled: 'Imewashwa',
                    payouts: 'Malipo',
                    export: 'Safirisha kwa Faili',
                    recentPayouts: 'Malipo ya Hivi Majuzi',
                    date: 'Tarehe Iliyoundwa',
                    store: 'Duka la M-PESA',
                    total_outstanding_amount: 'Jumla ya Kiasi Kinachodaiwa',
                    loanBalance: 'Usawa wa Mkopo',
                    transactionId: 'Kitambulisho cha Muamala',
                    transRef: 'Nambari ya Marejeleo ya Muamala',
                    completedOn: 'Tarehe ya Kukamilika',
                    mobile: 'Rununu',
                    loanCycle: 'Mzunguko wa mkopo',
                    score: 'Alama',
                    comment: 'Maoni',
                    systemStatus: 'Hali ya Mfumo',
                    updateContact: 'Sasisha Anwani',
                    addNewMessage: 'Tuma Ujumbe Mpya wa SMS',
                    sendMessage: 'Tuma Ujumbe wa SMS',
                    sendSmsTo: 'Tuma SMS Kwa:',
                    importContacts: 'Ingiza Anwani',
                    uploadContacts: 'Pakia Anwani',
                    importContact: 'Ingiza Anwani',
                    downloadSample: 'Pakua Sampuli',
                    addNewBulkMessage: 'Tuma SMS za Kikundi',
                    sendGroupMessage: 'Tuma Ujumbe wa SMS wa Kikundi',
                },
            },
        },
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: true,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
